import * as React from 'react';
import { BasePageStyles, globalColors } from '../../hooks/styles';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Box, Button, Card, Checkbox, FormControl, FormControlLabel, Grid, Input, InputAdornment, InputLabel, TextField, Tooltip } from '@mui/material';
import { IMAGES_URL } from '../../constants/Images';
import NumberInput from '../../components/numberPicker';
import { EvaluateExpression } from '../../hooks/functions/EvaluateExpression';
import config from '../../config';
import VideocamIcon from '@mui/icons-material/Videocam';
import queryString from 'query-string';

interface ProductProps {
	odrQuantity: number
	narcanQuantity: number
	odrPouchQuantity: number
	narcanChecked: boolean
	autoCalcNarcanChecked: boolean
	products: any[]
	setOdrQuantity: (quantity: number) => void
	setNarcanQuantity: (quantity: number) => void
	setOdrPouchQuantity: (quantity: number) => void
	setNarcanChecked: (isChecked: boolean) => void
	setAutoCalcNarcanChecked: (isChecked: boolean) => void
}



const odrBoxDescription = <div>
	<h2><strong>Description</strong></h2>

	<p>ODRescue&trade; Box is strategically designed to help make life-saving Naloxone readily available and is ideal for high-visibility areas to ensure swift action when needed. It was developed with first responder and nursing input to support rapid response. <b>Note: NARCAN&reg; Nasal Spray sold separately.</b></p>

	<h3>Key Features:</h3>

	<ul>
		<li>Clear box with handle for transportation</li>
		<li>Wall mount and hardware to make box visible and accessible</li>
		<li>Front identifies 5 common overdose signs</li>
		<li>Safety lock – to detect use or tampering</li>
		<li>Back includes QR codes to training video, report use, and reorder components</li>
	</ul>

	<h3>Included in each ODRescue&trade; Box</h3>

	<ul>
		<li>Clear plexiglass box with handle</li>
		<li>Wall mount and mounting hardware</li>
		<li>CPR kit (adult/child CPR mask, one-way valve with filter, nitrile gloves, antiseptic wipe)</li>
		<li>Customizable front for orders greater than 100 units</li>
		<li><b>Note: NARCAN&reg; Nasal Spray sold separately</b></li>
	</ul>
</div>

const odrPouchDescription = <div>
	<h2><strong>Description</strong></h2>

	<p>A compact and portable emergency kit tailored for at-risk populations. <strong>Note: naloxone available for purchase separately.</strong></p>
	<p>
		<a href="https://player.vimeo.com/video/960452819?h=240b65a4cf#t=" target="_blank" style={{
			color: '#ED1C24',
			display: 'flex'
		}}>
			<VideocamIcon 
				style={{
					color: '#ED1C24'
				}}
			/>
			<span>
				Watch the Demonstration Video
			</span>
		</a>
	</p>

	<h3>Key Features:</h3>

	<ul>
		<li>
			Sleek and lightweight pouch with a carabiner for easy transport
		</li>
		<li>
			Quick-access card with overdose signs and symptoms
		</li>
		<li>
			QR codes with links to training videos, reporting and reordering
		</li>
		<li>
			Emergency preparedness supplies
		</li>
	</ul>

	<h3>Included in Each ODRescue Pouch:</h3>

	<ul>
		<li>
			Black carrying case with a carabiner
		</li>
		<li>
			Laminated card with overdose signs, QR codes with links to training videos, reporting, and reordering
		</li>
		<li>
			CPR breathing shield with one way valve
		</li>
		<li>
			Pair of disposable vinyl gloves
		</li>
		<li>
			2 Alcohol wipes
		</li>
		<li>
			<strong>Note: naloxone available for purchase separately</strong>
		</li>
	</ul>
</div>

export const ProductComponent: React.FC<ProductProps> = (props) => {
	const classes = BasePageStyles();
	const history = useHistory(); 
	const NARCAN_PRODUCT_ID = 'NAR062702'
	const NARCAN_PRICE = 44.99; //hardcode bc looking up products from DB is deprecated, and we need it to have a different price here than on NWP.com //parseFloat(props.products.find((product: any) => product.id === NARCAN_PRODUCT_ID)?.price || 0);
	const ODR_KIT = config.odrKitProductId
	const ODR_KIT_PRICE = parseFloat(props.products.find((product: any) => product.id === ODR_KIT)?.price || 0);

	const ODR_POUCH = config.odrPouchProductId
	const ODR_POUCH_PRICE = 24.88;

	const MIN_QTY = 1;
	const MAX_QTY = 1000;

	const { productName } = useParams<any>();
	const { search } = useLocation();
	const values = queryString.parse(search);

	

	const isODRPouch = () => {
		return Boolean(productName && productName.toUpperCase() === 'POUCH')
	}

	const [odrQuantity, setOdrQuantity] = React.useState(props.odrQuantity || (isODRPouch() ? 0 : MIN_QTY));
	const [narcanQuantity, setNarcanQuantity] = React.useState(props.narcanQuantity || 0);
	const [odrPouchQuantity, setOdrPouchQuantity] = React.useState(props.odrPouchQuantity || (isODRPouch() ? 1 : 0));

	const [tooltipTitle, setTooltipTitle] = React.useState(`Min ${MIN_QTY}`);
	const [narcanTooltipTitle, setNarcanTooltipTitle] = React.useState(`Min ${MIN_QTY}`);

	const [qsDiscountCode, setQsDiscountCode] = React.useState('');

	const isFirstRender = React.useRef(true);

	if (values?.discountcode && !qsDiscountCode) {
		console.log('DISCOUNT CODE:', values?.discountcode);
		setQsDiscountCode(values.discountcode as string)
	}

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

	

	const getProductDescription = () => {
		if (isODRPouch()) {
			return odrPouchDescription
		}
		else {
			return odrBoxDescription
		}
	}

	const onBuyNow = () => {
		if (isODRPouch()) {
			onBuyNowPouch()
		}
		else {
			onBuyNowBox()
		}
	}

	const onBuyNowBox = () => {
		//Add to Cart
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			"event": "add_to_cart",
			"ecommerce": {
				"items": [{
					"index": 0,
					"price": ODR_KIT_PRICE,
					"coupon": "",
					"item_id": ODR_KIT,
					"discount": 0,
					"quantity": odrQuantity,
					"item_name": "ODRescue Box",
					"item_brand": "ODRescue",
					"affiliation": "FFF Enterprises",
					"item_variant": "",
					"item_category": ""
				},
				{
					"index": 0,
					"price": NARCAN_PRICE,
					"coupon": "",
					"item_id": NARCAN_PRODUCT_ID,
					"discount": 0,
					"quantity": narcanQuantity,
					"item_name": "NARCAN Nasal Spray 4 mg",
					"item_brand": "Narcan",
					"affiliation": "Narcan Shop",
					"item_variant": "",
					"item_category": ""
				}
			],
				"value": EvaluateExpression(`(${odrQuantity} * ${ODR_KIT_PRICE}) + (${narcanQuantity} * ${NARCAN_PRICE})`),
				"currency": "USD"
			}
		});
		props.setOdrQuantity(odrQuantity)
		props.setNarcanQuantity(narcanQuantity)
		props.setOdrPouchQuantity(0)

		if (qsDiscountCode) {
			routeChange(`/payment?discountcode=${qsDiscountCode}`)
		}
		else {
			routeChange('/payment')
		}
		
	}

	const onBuyNowPouch = () => {
		//Add to Cart
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			"event": "add_to_cart",
			"ecommerce": {
				"items": [{
					"index": 0,
					"price": ODR_POUCH_PRICE,
					"coupon": "",
					"item_id": ODR_POUCH,
					"discount": 0,
					"quantity": odrPouchQuantity,
					"item_name": "ODRescue Pouch Box",
					"item_brand": "ODRescue",
					"affiliation": "FFF Enterprises",
					"item_variant": "",
					"item_category": ""
				}
			],
				"value": EvaluateExpression(`(${odrPouchQuantity} * ${ODR_POUCH_PRICE})`),
				"currency": "USD"
			}
		});

		props.setOdrQuantity(0)
		//props.setNarcanQuantity(0)
		props.setNarcanQuantity(narcanQuantity)
		props.setOdrPouchQuantity(odrPouchQuantity)

		if (qsDiscountCode) {
			routeChange(`/payment/pouch?discountcode=${qsDiscountCode}`)
		}
		else {
			routeChange('/payment/pouch')
		}
	}

	const isTooltipOpen = () => {
		return !isFirstRender.current && (odrQuantity === MIN_QTY || odrQuantity === MAX_QTY)
	}

	const isNarcanTooltipOpen = () => {
		return !isFirstRender.current && (narcanQuantity === MIN_QTY || narcanQuantity === MAX_QTY * 2)
	}

	const onOdrQuantityChange = (newQty: number) => {
		if(newQty < MIN_QTY) newQty = MIN_QTY
		if(newQty > MAX_QTY) newQty = MAX_QTY
		setOdrQuantity(newQty)
		if (newQty === MIN_QTY) setTooltipTitle(`Min ${MIN_QTY}`)
		else if (newQty === MAX_QTY) setTooltipTitle(`Max ${MAX_QTY}, contact customer support to place larger orders.`)
		if(isFirstRender.current) isFirstRender.current = false;
		if(props.narcanChecked && props.autoCalcNarcanChecked) onNarcanQuantityChange(newQty * 2)
	}

const onOdrPouchQuantityChange = (newQty: number) => {
		if(newQty < MIN_QTY) newQty = MIN_QTY
		if(newQty > MAX_QTY) newQty = MAX_QTY

		setOdrPouchQuantity(newQty);
		
		if(props.narcanChecked && props.autoCalcNarcanChecked) onNarcanQuantityChange(newQty)
	}

	const onNarcanQuantityChange = (newQty: number) => {
		if (isODRPouch()) {
			if(newQty < MIN_QTY) {
				newQty = MIN_QTY
			}
			else if(newQty > MAX_QTY) {
				newQty = MAX_QTY
			}

			if (newQty === MIN_QTY) {
				setNarcanTooltipTitle(`Min ${MIN_QTY}`)
			}
			else if (newQty === MAX_QTY) {
				setNarcanTooltipTitle(`Max ${MAX_QTY}`)
			}
		}
		else {
			if(newQty < MIN_QTY) {
				newQty = MIN_QTY
			}
			else if(newQty > MAX_QTY * 2) {
				newQty = MAX_QTY * 2
			}

			if (newQty === MIN_QTY) {
				setNarcanTooltipTitle(`Min ${MIN_QTY}`)
			}
			else if (newQty === MAX_QTY * 2) {
				setNarcanTooltipTitle(`Max ${MAX_QTY * 2}`)
			}
		}
		
		setNarcanQuantity(newQty);

		if(isFirstRender.current) isFirstRender.current = false;
	}

	const onNarcanClicked = (isChecked: boolean) => {
		setNarcanQuantity(isChecked ? 
				(isODRPouch() ? (odrPouchQuantity) : (odrQuantity * 2)) 
		: 0);

		props.setNarcanChecked(isChecked)
		props.setAutoCalcNarcanChecked(true)
	}

	const onAutoCalcNarcanClicked = (isChecked: boolean) => {
		if(isChecked) {
			if (isODRPouch()) {
				onOdrPouchQuantityChange(odrPouchQuantity)
			}
			else {
				onNarcanQuantityChange(odrQuantity * 2)
			}
			
		}

		props.setAutoCalcNarcanChecked(isChecked)
	}

	React.useEffect(() => {
		//View Item
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			"event": "view_item",
			"ecommerce": {
				"items": [{
					"index": 0,
					"price": ODR_KIT_PRICE,
					"coupon": "",
					"item_id": ODR_KIT,
					"discount": 0,
					"quantity": odrQuantity,
					"item_name": "ODRescue Box",
					"item_brand": "ODRescue",
					"affiliation": "FFF Enterprises",
					"item_variant": "",
					"item_category": ""
				},
				{
					"index": 0,
					"price": NARCAN_PRICE,
					"coupon": "",
					"item_id": NARCAN_PRODUCT_ID,
					"discount": 0,
					"quantity": narcanQuantity,
					"item_name": "NARCAN Nasal Spray 4 mg",
					"item_brand": "Narcan",
					"affiliation": "Narcan Shop",
					"item_variant": "",
					"item_category": ""
				}
			],
				"value": EvaluateExpression(`(${odrQuantity} * ${ODR_KIT_PRICE}) + (${narcanQuantity} * ${NARCAN_PRICE})`),
				"currency": "USD"
			}
		});
	}, []);

	return (
		<Grid container item xs={12} md={8}>
			<Grid item xs={12}>
				<h1 style={{
					marginBottom: 0
				}}>
					Order ODRescue&trade; {isODRPouch() ? 'Pouch' : 'Box'}
				</h1>
				{
					Boolean(qsDiscountCode) && <h2 style={{
						color: 'green'
					}}>
						Your discount code "{qsDiscountCode}" will be applied on the checkout page.
					</h2>
				}
			</Grid>
			<Grid container item xs={12} md={6}>
				<Box sx={{paddingLeft: 0}}>

				<Grid container item xs={12} md={12} sx={{display: 'flex', justifyContent: 'space-between'}}>
					{/*!isODRPouch() &&*/ <>
							<Grid >
								<Tooltip title={tooltipTitle} open={isTooltipOpen()} PopperProps={{ disablePortal: true }} arrow>
									<NumberInput aria-label="Quantity Input" min={MIN_QTY} max={MAX_QTY} 
										title={props.narcanChecked ? `ODRescue\u2122 ${isODRPouch() ? 'Pouch' : 'Box'}` : ''}
										value={isODRPouch() ? odrPouchQuantity : odrQuantity}
										onChange={(event, newValue) => {
											isODRPouch() ?
											onOdrPouchQuantityChange(newValue || 0) :
											onOdrQuantityChange(newValue || 0)
										}}
										onInputChange={(event) => {
											isODRPouch() ?
											onOdrPouchQuantityChange(parseInt(event.target.value || '0')) :
											onOdrQuantityChange(parseInt(event.target.value || '0'))
										}}
										endAdornment={<InputAdornment position="end" sx={{marginLeft: '-35px', marginRight: '18px'}}>bx</InputAdornment>}
									/>
								</Tooltip>
							</Grid>
								{props.narcanChecked && 
									<Grid sx={{maxWidth: '50%'}}>
										<Tooltip title={narcanTooltipTitle} open={isNarcanTooltipOpen()} PopperProps={{ disablePortal: true }} arrow>
											<NumberInput aria-label="Quantity Input" min={MIN_QTY} max={isODRPouch() ? MAX_QTY : MAX_QTY*2} 
												title={'Narcan\u00AE Nasal Spray 4 mg'}
												value={narcanQuantity}
												onChange={(event, newValue) => {onNarcanQuantityChange(newValue || 0)}}
												onInputChange={(event) => {onNarcanQuantityChange(parseInt(event.target.value || '0'))}}
												endAdornment={<InputAdornment position="end" sx={{marginLeft: '-35px', marginRight: '18px'}}>bx</InputAdornment>}
												disabled={props.autoCalcNarcanChecked}
											/>
										</Tooltip>
										<FormControlLabel
											label={(<span>{isODRPouch() ? 'One box' : 'Two boxes'} of NARCAN&reg; ({isODRPouch() ? '2' : '4'} doses) per ODRescue&trade; {isODRPouch() ? 'Pouch' : 'Box'}</span>)}
											className={classes.termsCheck}
											sx={{display: 'flex', justifyContent: 'center', marginTop: '18px'}}
											control={
												<Checkbox
													checked={props.autoCalcNarcanChecked}
													onChange={(event, isChecked) => {onAutoCalcNarcanClicked(isChecked)}}
													inputProps={{ 'aria-label': 'controlled' }}
													size={'small'}
												/>
											}
										/>
											
									</Grid>
								}
						</>
					}{/*** end non-pouch case ****/}
					{/*isODRPouch() && 
						<>
							<Grid >
								<Tooltip title={tooltipTitle} open={isTooltipOpen()} PopperProps={{ disablePortal: true }} arrow>
									<NumberInput aria-label="Quantity Input" min={MIN_QTY} max={MAX_QTY} 
										title={`ODRescue\u2122 ${isODRPouch() ? 'Pouch' : 'Box'}`}
										value={odrPouchQuantity}
										onChange={(event, newValue) => {onOdrPouchQuantityChange(newValue || 0)}}
										onInputChange={(event) => {onOdrPouchQuantityChange(parseInt(event.target.value || '0'))}}
										endAdornment={<InputAdornment position="end" sx={{marginLeft: '-35px', marginRight: '18px'}}>pc</InputAdornment>}
									/>
								</Tooltip>
							</Grid>

						</>
					*/}{/*** end pouch case ****/}
				</Grid>
				<Grid item xs={12} sx={{display: 'flex'}}>
						<FormControlLabel
							label={(<span>Add NARCAN&reg; Nasal Spray 4mg</span>)}
							className={classes.termsCheck}
							control={
								<Checkbox
									checked={props.narcanChecked}
									onChange={(event, isChecked) => {onNarcanClicked(isChecked)}}
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							}
						/>
					</Grid>
					<Grid  item xs={12} sx={{ marginTop: '20px'}}>
						<Button variant="contained" size='large'
							onClick={onBuyNow}
						>Buy Now</Button>
					</Grid>
				</Box>
				<Grid item xs={12} md={12}>
					{getProductDescription()}
				</Grid>
			</Grid>

			<Grid item xs={12} md={6}>
				<img src={isODRPouch() ? IMAGES_URL.ODR_POUCH : IMAGES_URL.ODR_PRODUCT} style={{maxWidth: '100%'}}></img>
			</Grid>
			
			<Grid item xs={12} sx={{textAlign: 'center', paddingTop: '50px'}}>
				<p>NARCAN&reg; is a registered trademark of Emergent Operations Ireland Limited.</p>
			</Grid>
		</Grid>
	)
}